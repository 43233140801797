import { http, HttpResponse } from "msw";
import { mockGetUploadRequestUrlQuery } from "src/gql/graphql";

export const handlers = [
  mockGetUploadRequestUrlQuery(() => {
    return HttpResponse.json({
      data: {
        uploadRequestUrl: {
          url: "http://upload.example.com",
          method: "POST",
          headers: '{"Content-Type": "application/octet-stream"}',
        },
      },
    });
  }),

  http.post("http://upload.example.com", ({ request }) => {
    if (request.headers.get("Content-Type") !== "application/octet-stream") {
      return new HttpResponse("Invalid content type", { status: 400 });
    }
    return new HttpResponse("File uploaded", { status: 200 });
  }),
];
