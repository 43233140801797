import { atomWithStorage } from "jotai/utils";
import { z } from "zod";
import { atomEffect } from "jotai-effect";
import { worker } from "./browser";

export const mswConfigLocalStorageKey = "mswConfig";

export const mswConfigSchema = z.object({
  enabled: z.boolean(),
});

export type MswConfig = z.infer<typeof mswConfigSchema>;

const mswConfigAtom = atomWithStorage<MswConfig>(
  mswConfigLocalStorageKey,
  {
    enabled: false,
  },
  undefined,
  { getOnInit: true }
);

export const syncMswWorkerAtomEffect = atomEffect((get) => {
  const mswConfig = get(mswConfigAtom);
  if (mswConfig.enabled) {
    void worker.start({
      onUnhandledRequest: "bypass",
    });
  } else {
    worker.stop();
  }
});

export default mswConfigAtom;
