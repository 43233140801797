import { useCurrentTenant, useIsMobile } from "app/jm";
import {
  OAuthProvider,
  signInWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { app } from "app/Firebase";
import type { FormEvent } from "react";
import { useCallback, useState, useRef, useEffect } from "react";
import { FirebaseError } from "firebase/app";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import Toaster from "src/app/Toaster";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { z } from "zod";
import { useFirebaseDownloadURL } from "./App";

const auth = getAuth(app);

const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.setCustomParameters({
  tenant: "1aa2a47f-4fd3-462d-b36d-320b5d3dfda1",
  prompt: "select_account",
});

export const Login = () => {
  const { tenantId, publicTenantAssetsUrl, themeColour } = useCurrentTenant();
  const nodeRef = useRef(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [uiState, setUiState] = useState<
    "email" | "password" | "externalProvider"
  >("email");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = useIsMobile();
  useEffect(() => {
    if (uiState === "password" && passwordRef.current) {
      passwordRef.current.focus();
    } else if (uiState === "email" && emailRef.current) {
      emailRef.current.focus();
    }
  }, [uiState]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setIsSubmitting(true);
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const object = Object.fromEntries(formData.entries());
      if (tenantId !== "jmadmin" && !auth.tenantId) {
        throw new Error("No tenantId set");
      }
      const emailParseResult = z.string().safeParse(object.email);
      if (!emailParseResult.success) {
        Toaster.error("Invalid email address");
        setIsSubmitting(false);
        return;
      }
      if (uiState === "email") {
        if (tenantId === "jmadmin") {
          setUiState("password");
          setIsSubmitting(false);
          return;
        }
        void fetch(
          `/api/userAuthProvider/${encodeURIComponent(emailParseResult.data)}?tenant=${tenantId}`,
          {
            method: "GET",
          }
        ).then((response) => {
          if (response.ok) {
            void response.json().then((data) => {
              const { authProvider } = data;
              if (authProvider === "microsoft") {
                setUiState("externalProvider");
                signInWithPopup(auth, microsoftProvider).catch((error) => {
                  console.log(error);
                });
              } else {
                setUiState("password");
                setIsSubmitting(false);
              }
            });
          }
          throw new Error("Network response was not ok");
        });
      } else if (uiState === "password") {
        console.log("Sigining in with PAssword");
        void signInWithEmailAndPassword(
          auth,
          String(object.email),
          String(object.password)
        ).catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          if (error instanceof FirebaseError) {
            switch (error.code) {
              case "auth/invalid-email":
                Toaster.error("Invalid email address");
                break;
              case "auth/missing-password":
                Toaster.error("Missing password");
                break;
              case "auth/user-not-found":
                Toaster.error("User not found");
                break;
              case "auth/wrong-password":
                Toaster.error("Incorrect password");
                break;
              default:
                Toaster.error("An unknown error occurred");
                break;
            }
            return;
          }
          Toaster.error("An unknown error occurred");
        });
      }
    },
    [tenantId, uiState]
  );
  const { firebaseTenantId } = useCurrentTenant();

  const { data: logoUrl } = useFirebaseDownloadURL(
    `tenants/${firebaseTenantId}/public/logo.png`
  );

  return (
    <div
      className="w-full h-full bg-cover p-0 overflow-hidden bg-black bg-opacity-50 
  grid place-items-center grid-rows-[4fr_2fr_4fr_4fr] sm:grid"
    >
      <div className="h-full w-full grid place-items-center row-[2]">
        <div
          className={`bg-white/85 ${isMobile ? "min-w-[100%] h-[100%] p-3" : "min-w-[400px] rounded-3xl p-4 "} max-w-[400px]`}
        >
          <div
            className="jm-login-logo-header"
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              paddingBottom: "2em",
            }}
          >
            {tenantId === "jmadmin" ? (
              <span className="text-lg">Job Manager Admin</span>
            ) : (
              <img className="jm-login-tenant-logo w-28" src={logoUrl} />
            )}
            <img
              className="jm-login-logo"
              src="assets/img/jm_logo_small.png"
              style={{ width: 160 }}
            />
          </div>
          {/* <hr className="mt-2" /> */}
          <form
            onSubmit={isSubmitting ? () => null : handleSubmit}
            className="flex flex-col space-y-4"
          >
            <div className="flex items-center">
              <div className="text-xl flex flex-1">Login</div>
              {uiState !== "email" && (
                <a
                  href="#"
                  onClick={() => {
                    setUiState("email");
                    setIsSubmitting(false);
                  }}
                >
                  Switch User
                </a>
              )}
            </div>
            <InputGroup
              inputRef={emailRef}
              type="email"
              name="email"
              placeholder="Email"
              readOnly={uiState === "password"}
            />
            {/* {uiState === "password" && <span>Change User</span>} */}
            {/* Password Field Transition */}
            <TransitionGroup component={null}>
              {uiState === "password" && (
                <CSSTransition
                  // nodeRef={nodeRef}
                  key="password"
                  timeout={500}
                  classNames={{
                    enter: "animate-growIn",
                    exit: "animate-shrinkOut",
                  }}
                  unmountOnExit
                >
                  <InputGroup
                    ref={nodeRef}
                    className="overflow-hidden"
                    type="password"
                    name="password"
                    placeholder="Password"
                    inputRef={passwordRef}
                  />
                </CSSTransition>
              )}
            </TransitionGroup>

            <Button
              intent="primary"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              // style={{
              //   background: themeColour,
              // }}
              text={uiState === "email" ? "Next" : "Submit"}
            />
            {/* <Button
                text="Toggle"
                onClick={() =>
                  setUiState(uiState === "email" ? "password" : "email")
                }
              /> */}

            {/* <FormGroup label="Password">
              <InputGroup type="password" name="password" />
            </FormGroup> */}

            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button intent="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
              {tenantId !== "jmadmin" && (
                <a
                  id="forgot"
                  href="#"
                  onClick={() => {
                    console.log("To be implemented...");
                  }}
                >
                  Forgot password
                </a>
              )}
            </div> */}
          </form>
          {/* <hr className="mt-2" /> */}
          {/* {tenantId !== "jmadmin" && (
            <div className="mt-2">
              <Button
                onClick={() => {
                  if (tenantId !== "jmadmin" && !auth.tenantId) {
                    throw new Error("No tenantId set");
                  }
                  signInWithPopup(auth, microsoftProvider).catch((error) => {
                    console.log(error);
                  });
                }}
                // Need to use inline styles because Tailwind doesn't support
                // dynamic arbitrary styles
                // https://v2.tailwindcss.com/docs/just-in-time-mode#known-limitations
                style={{
                  background: themeColour,
                }}
                className="text-white w-[100%]"
                disabled={isSubmitting}
              >
                Staff & Contractor Login
              </Button>
            </div>
          )} */}
          <p className="text-gray-1 pt-2 text-center text-xs mb-0">
            Version: {window.JM_VERSION}
          </p>
        </div>
      </div>
    </div>
  );
};
