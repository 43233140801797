import { atom } from "jotai";
import getWindowAtom from "app/uiState/getWindowAtom";

let zIndex = 1;

const activeWindowAtomPrimitive = atom<string | null>(null);

const activeWindowAtom = atom(
  (get) => get(activeWindowAtomPrimitive),
  (get, set, id: string | null) => {
    // Only update if the window is not already active
    // otherwise new window state will be generated,
    // causing re-renders
    if (get(activeWindowAtomPrimitive) === id) {
      return;
    }
    if (id) {
      set(getWindowAtom(id), (state) => {
        // Because could be null
        if (!state) return state;
        return {
          ...state,
          uiProps: {
            ...state.uiProps,
            zIndex: zIndex++,
            minimised: false,
          },
        };
      });
    }
    set(activeWindowAtomPrimitive, id);
  }
);

export default activeWindowAtom;
