import { atom } from "jotai";
import activeWindowAtom from "app/uiState/activeWindowAtom";
import getWindowAtom from "app/uiState/getWindowAtom";
import openedWindowIdsAtom from "app/uiState/openedWindowIdsAtom";

const closeWindowAtom = atom(null, (get, set, windowId: string) => {
  set(openedWindowIdsAtom, (openedWindowIds) => {
    return openedWindowIds.filter((id) => id !== windowId);
  });
  getWindowAtom.remove(windowId);

  if (get(activeWindowAtom) === windowId) {
    set(activeWindowAtom, null);
  }
});

export default closeWindowAtom;
