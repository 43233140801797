import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const devToolsLocalStorageKey = "jm-dev-tools";

const devToolsConfigAtom = atomWithStorage(
  devToolsLocalStorageKey,
  {
    reactQueryDevTools: {
      enabled: false,
    },
    scratchPad: {
      enabled: false,
    },
  },
  undefined,
  {
    getOnInit: true,
  }
);

export default devToolsConfigAtom;
