export const getTenantId = () => {
  // If DEV, then specify tenant via eg http://localhost:8080?tenant=trendskitchens
  if (window.JM_ENVIRONMENT === "DEVELOPMENT") {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("tenant");
  } else {
    // grab tenant from url
    const hostname = window.location.hostname;
    const tenantId = hostname.split(".")[0];
    return tenantId;
  }
};
