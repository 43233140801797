import type { EventObject } from "xstate";

/**
 * @deprecated Use xstate's own assertEvent() helper
 */
export function assertXStateEventType<
  TE extends EventObject,
  TType extends TE["type"],
>(event: TE, eventType: TType): asserts event is TE & { type: TType } {
  if (event.type !== eventType) {
    throw new Error(
      `Invalid event: expected "${eventType}", got "${event.type}"`
    );
  }
}

export function assert(
  condition: unknown,
  message = "Assertion failed"
): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
