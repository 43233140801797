import { atomFamily } from "jotai/utils";
import { atom } from "jotai";
import type { ComponentState, ComponentName } from "app/componentOptions";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getWindowAtom = atomFamily((id: string) =>
  atom<ComponentState<ComponentName> | null>(null)
);

export default getWindowAtom;
