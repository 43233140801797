import { Position, OverlayToaster, ToastProps } from "@blueprintjs/core";
import { ReactNode } from "react";
import { createRoot } from "react-dom/client";

const AppToaster = OverlayToaster.createAsync(
  {
    position: Position.TOP,
  },
  {
    // https://blueprintjs.com/docs/#core/components/toast.example
    domRenderer: (toaster, containerElement) =>
      createRoot(containerElement).render(toaster),
  }
);

const timeout = 7500;

export default {
  instance: AppToaster,
  show: (props: ToastProps) =>
    void AppToaster.then((toaster) =>
      toaster.show({ className: "app-toaster", ...props })
    ),
  error: (message: string, title?: string) => {
    void AppToaster.then((toaster) =>
      toaster.show({
        intent: "danger",
        className: "app-toaster",
        message: title ? (
          <span>
            <b>{title}</b>
            <br />
            {message}
          </span>
        ) : (
          message
        ),
        icon: "error",
        timeout,
      })
    );
  },
  success: (message: string, title?: string) => {
    void AppToaster.then((toaster) =>
      toaster.show({
        intent: "success",
        className: "app-toaster",
        message: title ? (
          <span>
            <b>{title}</b>
            <br />
            {message}
          </span>
        ) : (
          message
        ),
        icon: "tick",
        timeout,
      })
    );
  },
  warning: (message: string, title?: string) => {
    void AppToaster.then((toaster) =>
      toaster.show({
        intent: "warning",
        className: "app-toaster",
        message: title ? (
          <span>
            <b>{title}</b>
            <br />
            {message}
          </span>
        ) : (
          message
        ),
        icon: "warning-sign",
        timeout,
      })
    );
  },
  info: (message: ReactNode, title?: string) => {
    void AppToaster.then((toaster) =>
      toaster.show({
        intent: "none",
        className: "app-toaster",
        message: title ? (
          <span>
            <b>{title}</b>
            <br />
            {message}
          </span>
        ) : (
          message
        ),
        icon: "info-sign",
        timeout,
      })
    );
  },
};
