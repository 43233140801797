import { useState, useEffect } from "react";
import { Overlay2, Colors } from "@blueprintjs/core";

// Load the icon directly so it is preloaded (hopefully!) and can been seen when offline
// See https://blueprintjs.com/docs/#icons/loading-icons
import { Offline } from "@blueprintjs/icons";

function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      console.log("Browser is online again");
      setIsOnline(true);
    };

    const handleOffline = () => {
      // TODO: Perhaps capture this as a metric in Sentry?
      console.log("Browser is offline");
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  // We conditionally return the component, to make sure it is placed top-most in the overlay stack when enabled
  return !isOnline ? (
    <Overlay2 isOpen={true}>
      <div
        style={{
          padding: 10,
          background: Colors.ORANGE5,
          width: "100%",
          justifyContent: "center",
          display: "flex",
          gap: 5,
        }}
      >
        <Offline color={Colors.ORANGE1} />
        <span style={{ color: Colors.ORANGE1 }}>Check internet connection</span>
      </div>
    </Overlay2>
  ) : null;
}

export default NetworkStatus;
