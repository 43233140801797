import { createElement } from "react";
import { createRoot } from "react-dom/client";
import { App } from "src/app/App";
import { assert } from "lib/util/assert";
import * as Sentry from "@sentry/react";
import { mswConfigLocalStorageKey, mswConfigSchema } from "./msw/mswConfigAtom";

if (window.JM_ENVIRONMENT !== "DEVELOPMENT") {
  Sentry.init({
    dsn: "https://55b7486698025f0a90935007b70807bc@o515028.ingest.sentry.io/4506107431026688",
    environment: window.JM_ENVIRONMENT
  });
}

async function enableMocking() {
  if (
    window.JM_ENVIRONMENT !== "DEVELOPMENT" &&
    window.JM_ENVIRONMENT !== "TESTING"
  ) {
    return;
  }

  const mswConfig = localStorage.getItem(mswConfigLocalStorageKey);

  if (mswConfig === null) {
    return;
  }

  const parseResult = mswConfigSchema.safeParse(JSON.parse(mswConfig));
  if (!parseResult.success) {
    console.error("Failed to parse mswConfig.", parseResult.error);
    return;
  }

  if (parseResult.data.enabled === false) {
    return;
  }

  const { worker } = await import("./msw/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: "bypass",
  });
}

const container = document.getElementById("app");
assert(container);
const root = createRoot(container);

// See https://mswjs.io/docs/integrations/browser
// If you get a SSL error see https://mswjs.io/docs/recipes/using-local-https
// Set chrome flags: allow-insecure-localhost to enabled
enableMocking().then(() => {
  root.render(createElement(App));
});
