import type { ReactNode } from "react";
import { createContext, useMemo } from "react";
import { useAtom } from "jotai";
import getWindowAtom from "app/uiState/getWindowAtom";

interface WindowStateProviderProps {
  children: ReactNode;
  windowId: string;
}

export const WindowContext = createContext<{ windowId: string } | null>(null);

// Provides a unique Context for each window
const WindowStateProvider = ({
  windowId,
  children,
}: WindowStateProviderProps) => {
  //console.log("Render WindowStateProvider");

  const windowContextValue = useMemo(() => ({ windowId }), [windowId]);

  const [componentState, setComponentState] = useAtom(getWindowAtom(windowId));
  if (!componentState) {
    return <span>{`Window "${windowId}" not found`}</span>;
  }

  return (
    <WindowContext.Provider value={windowContextValue}>
      {children}
    </WindowContext.Provider>
  );
};

export { WindowStateProvider };
