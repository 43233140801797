import type { JSONSchema } from "json-schema-to-ts";
import type { ExtendedJSONSchema, FromExtendedSchema } from "json-schema-to-ts";

export const schema = {
  type: "object",
  properties: {
    processType: {
      type: "object",
      properties: {
        id: {
          type: "string",
          title: "ID",
        },
        name: {
          type: "string",
          title: "Name",
        },
      },
      title: "Process Type",
      required: ["id"],
    },
  },
  additionalProperties: false,
  required: ["processType"],
} as const satisfies JSONSchema;

export const uiSchema = {
  processType: {
    "ui:field": "processType",
  },
} as const;
