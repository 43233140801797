import { atom } from "jotai";
import activeWindowAtom from "app/uiState/activeWindowAtom";
import uniqueId from "lodash/uniqueId";
import openedWindowIdsAtom from "app/uiState/openedWindowIdsAtom";
import getWindowAtom from "app/uiState/getWindowAtom";
import {
  ComponentName,
  ComponentProps,
  UIProps,
  componentOptions,
} from "app/componentOptions";

type Config<T extends ComponentName> = {
  componentType: ComponentName;
  componentProps?: ComponentProps<ComponentName>;
  uiProps: Partial<UIProps>;
  windowId?: string;
};

// Unfortunately there is no easy way to pass a parameter requiring a Generic (ie Config<T> in this case)
// to an atom definition. We would need to grab the Getter and Setter types from jotai but these are not exported.
const openWindowAtom = atom(
  null,
  <T extends ComponentName>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set: any,
    { componentType, componentProps, uiProps = {}, windowId }: Config<T>
  ) => {
    // if (cmp.singleton || cmp.singletonByConfig) {
    //   // Catch attempts to open an already-open component marked as a singleton. Activate existing window instead.
    //
    //   const existingOpenWindow = Object.entries(get(openedWindowsAtom)).find(
    //     ([id, openWindow]) =>
    //       openWindow.componentId == componentId &&
    //       (cmp.singletonByConfig
    //         ? isEqual(openWindow.componentProps, componentProps)
    //         : true)
    //   );
    //
    //   if (existingOpenWindow) {
    //     set(activeWindowAtom, existingOpenWindow[0]);
    //     return;
    //   }
    // }

    // Allows us to override the windowId if we want to
    // Useful for in a storybook
    const finalWindowId = windowId || uniqueId("window-");

    set(openedWindowIdsAtom, (ids: string[]) => [...ids, finalWindowId]);

    set(getWindowAtom(finalWindowId), {
      componentType,
      componentProps,
      uiProps: {
        ...componentOptions[componentType].defaultUiOptions,
        ...uiProps,
      },
    });

    set(activeWindowAtom, finalWindowId);
  }
);

export default openWindowAtom;
